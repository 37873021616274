import { useContext } from 'react';
import { gql, useMutation, useQuery, FetchPolicy } from '@apollo/client';
import { useEmitEvent } from '@/storychief/hooks';
import { COMMENT_EVENTS } from '@/comments/constants/Constants';
import usePreview from '@/storychief/hooks/usePreview';
import useModelSignKey from '@/storychief/hooks/useModelSignKey';
import CommentsContext from '@/comments/context/CommentsContext';
import useModal from '@/modals/hooks/useModal';
import useUserSignKey from '@/storychief/hooks/useUserSignKey';
import { CommentsProviderReturnType } from '@/comments/components/CommentsProvider.tsx';

const commentUserFragment = gql`
  fragment CommentUser on User {
    __typename
    id
    firstname
    lastname
    profile_picture
  }
`;

const commentFragment = gql`
  fragment Comment on Comment {
    __typename
    id
    content
    created_at
    deleted_at
    resolved
    updated_at
    user_email
    visibility
    user {
      ...CommentUser
    }
    editor_key
    editor_ranges
    editor_text
    parent {
      __typename

      content
      created_at
      deleted_at
      id
      resolved
      updated_at
      user_email

      user {
        ...CommentUser
      }
    }
    replies {
      __typename
      id
      content
      created_at
      deleted_at
      resolved
      updated_at
      user_email
      user {
        ...CommentUser
      }
    }
  }
  ${commentUserFragment}
`;

const fragments = {
  comment: commentFragment,
  postset: gql`
    fragment PostsetComments on Postset {
      __typename
      id
      comments(filter: { isReply: false }, orderBy: [{ column: "created_at", order: DESC }]) {
        ...Comment
      }
    }
    ${commentFragment}
  `,
  story: gql`
    fragment StoryComments on Story {
      __typename
      id
      comments(filter: { isReply: false }, orderBy: [{ column: "created_at", order: DESC }]) {
        ...Comment
      }
    }
    ${commentFragment}
  `,
  webinar: gql`
    fragment WebinarComments on Webinar {
      __typename
      id
      comments(filter: { isReply: false }, orderBy: [{ column: "created_at", order: DESC }]) {
        ...Comment
      }
    }
    ${commentFragment}
  `,
  podcast: gql`
    fragment PodcastComments on Podcast {
      __typename
      id
      comments(filter: { isReply: false }, orderBy: [{ column: "created_at", order: DESC }]) {
        ...Comment
      }
    }
    ${commentFragment}
  `,
  videoproject: gql`
    fragment VideoProjectComments on VideoProject {
      __typename
      id
      comments(filter: { isReply: false }, orderBy: [{ column: "created_at", order: DESC }]) {
        ...Comment
      }
    }
    ${commentFragment}
  `,
  websitecontent: gql`
    fragment WebsiteContentComments on WebsiteContent {
      __typename
      id
      comments(filter: { isReply: false }, orderBy: [{ column: "created_at", order: DESC }]) {
        ...Comment
      }
    }
    ${commentFragment}
  `,
  newsletter: gql`
    fragment NewsletterComments on Newsletter {
      __typename
      id
      comments(filter: { isReply: false }, orderBy: [{ column: "created_at", order: DESC }]) {
        ...Comment
      }
    }
    ${commentFragment}
  `,
  ebook: gql`
    fragment EbookComments on Ebook {
      __typename
      id
      comments(filter: { isReply: false }, orderBy: [{ column: "created_at", order: DESC }]) {
        ...Comment
      }
    }
    ${commentFragment}
  `,
  task: gql`
    fragment TaskComments on Task {
      __typename
      id
      comments(filter: { isReply: false }, orderBy: [{ column: "created_at", order: DESC }]) {
        ...Comment
      }
    }
    ${commentFragment}
  `,
};

const EXTERNAL_CREATE_COMMENT_MUTATION = gql`
  mutation ExternalCreateComment($input: CreateCommentInput!) {
    createComment: publicCreateComment(input: $input) {
      ... on CommentInterface {
        id
        total_comments
        comments(filter: { isReply: false }, orderBy: [{ column: "created_at", order: DESC }]) {
          ...Comment
        }
      }
    }
  }
  ${commentFragment}
`;

const INTERNAL_CREATE_COMMENT_MUTATION = gql`
  mutation CreateComment($input: CreateCommentInput!) {
    createComment(input: $input) {
      ... on CommentInterface {
        id
        total_comments
        comments(filter: { isReply: false }, orderBy: [{ column: "created_at", order: DESC }]) {
          ...Comment
        }
      }
    }
  }
  ${commentFragment}
`;

const COMMENT_QUERIES = {
  Story: gql`
    query StoryComments($id: ID!) {
      __typename
      data: story(id: $id) {
        __typename
        id
        created_at
        updated_at
        deleted_at
        ...StoryComments
      }
    }
    ${fragments.story}
  `,
  Postset: gql`
    query PostsetComments($id: ID!) {
      __typename
      data: postset(id: $id) {
        ...PostsetComments
      }
    }
    ${fragments.postset}
  `,
  Webinar: gql`
    query WebinarComments($id: ID!) {
      __typename
      data: webinar(id: $id) {
        __typename
        id
        ...WebinarComments
      }
    }
    ${fragments.webinar}
  `,
  Ebook: gql`
    query EbookComments($id: ID!) {
      __typename
      data: ebook(id: $id) {
        __typename
        id
        ...EbookComments
      }
    }
    ${fragments.ebook}
  `,
  VideoProject: gql`
    query VideoProjectComments($id: ID!) {
      __typename
      data: videoProject(id: $id) {
        __typename
        id
        ...VideoProjectComments
      }
    }
    ${fragments.videoproject}
  `,
  WebsiteContent: gql`
    query WebsiteContentComments($id: ID!) {
      __typename
      data: websiteContent(id: $id) {
        __typename
        id
        ...WebsiteContentComments
      }
    }
    ${fragments.websitecontent}
  `,
  Podcast: gql`
    query PodcastComments($id: ID!) {
      __typename
      data: podcast(id: $id) {
        __typename
        id
        ...PodcastComments
      }
    }
    ${fragments.podcast}
  `,
  Newsletter: gql`
    query NewsletterComments($id: ID!) {
      __typename
      data: newsletter(id: $id) {
        __typename
        id
        ...NewsletterComments
      }
    }
    ${fragments.newsletter}
  `,
  Task: gql`
    query TaskComments($id: ID!) {
      __typename
      data: task(id: $id) {
        __typename
        id
        ...TaskComments
      }
    }
    ${fragments.task}
  `,
};

const PUBLIC_COMMENT_QUERIES = {
  Story: gql`
    query PublicStoryComments($id: ID!) {
      __typename
      data: publicStory(id: $id) {
        __typename
        id
        ...StoryComments
      }
    }
    ${fragments.story}
  `,
  Postset: gql`
    query PublicPostsetComments($id: ID!) {
      __typename
      data: publicPostset(id: $id) {
        ...PostsetComments
      }
    }
    ${fragments.postset}
  `,
  Webinar: gql`
    query PublicWebinarComments($id: ID!) {
      __typename
      data: publicWebinar(id: $id) {
        __typename
        id
        ...WebinarComments
      }
    }
    ${fragments.webinar}
  `,
  Ebook: gql`
    query PublicEbookComments($id: ID!) {
      __typename
      data: publicEbook(id: $id) {
        __typename
        id
        ...EbookComments
      }
    }
    ${fragments.ebook}
  `,
  VideoProject: gql`
    query PublicVideoProjectComments($id: ID!) {
      __typename
      data: publicVideoProject(id: $id) {
        __typename
        id
        ...VideoProjectComments
      }
    }
    ${fragments.videoproject}
  `,
  WebsiteContent: gql`
    query PublicWebsiteContentComments($id: ID!) {
      __typename
      data: publicWebsiteContent(id: $id) {
        __typename
        id
        ...WebsiteContentComments
      }
    }
    ${fragments.websitecontent}
  `,
  Podcast: gql`
    query PublicPodcastComments($id: ID!) {
      __typename
      data: publicPodcast(id: $id) {
        __typename
        id
        ...PodcastComments
      }
    }
    ${fragments.podcast}
  `,
  Newsletter: gql`
    query PublicNewsletterComments($id: ID!) {
      __typename
      data: publicNewsletter(id: $id) {
        __typename
        id
        ...NewsletterComments
      }
    }
    ${fragments.newsletter}
  `,
};

type AddComment = {
  content: string;
  visibility: number;
  parentId?: string;
  editorKey?: string;
  editorRanges?: object;
  editorText?: string[];
};

type Comment = {
  __typename?: 'Comment';
  id: string;
  content: string;
  created_at: string;
  deleted_at?: string;
  editor_key?: string;
  editor_ranges?: string;
  editor_text?: string[];
  model_id: string;
  model_type: string;
  parent?: Comment;
  parent_id?: string;
  properties?: string;
  replies: Comment[];
  resolved: boolean;
  updated_at: string;
  user_email?: string;
  visibility: number;
};

type UseCommentsReturnType = CommentsProviderReturnType & {
  isCommentsSetupDone: boolean;
  comments: Comment[];
  loadingCommentUsers: boolean;
  refetchComments: () => void;
  addComment: (comment: AddComment) => void;
  loadingAddComment: boolean;
  modelSignKey?: object;
};

function useComments(fetchPolicy: FetchPolicy = 'cache-first'): UseCommentsReturnType {
  // Hooks
  const {
    modelId,
    modelType,
    filter,
    setFilter,
    selectedCommentId,
    setSelectedCommentId,
    isNewGeneralCommentActive,
    setIsNewGeneralCommentActive,
  } = useContext(CommentsContext);

  const previewIdentifyModal = useModal('PreviewIdentifyModal');

  const emitCreateEvent = useEmitEvent(COMMENT_EVENTS.create);
  const { preview, previewCanComment } = usePreview();
  const userSignKey = useUserSignKey();
  const modelSignKey = useModelSignKey({
    typename: modelType,
    id: modelId,
  });
  const mutationContext = preview
    ? {
        credentials: 'omit',
        headers: {
          ...userSignKey,
          ...modelSignKey,
        },
      }
    : {};

  // Queries
  const {
    data: { data } = {},
    loading: loadingCommentUsers,
    refetch: refetchComments,
  } = useQuery(getCommentsQuery(), {
    variables: {
      id: modelId,
    },
    returnPartialData: true,
    fetchPolicy,
    context: mutationContext,
  });

  const comments = data?.comments || [];

  // Mutations
  const [addCommentMutation, { loading: loadingAddComment }] = useMutation(getMutation(), {
    context: mutationContext,
  });

  // Functions
  function getCommentsQuery() {
    return preview ? PUBLIC_COMMENT_QUERIES[modelType] : COMMENT_QUERIES[modelType];
  }

  function getMutation() {
    return preview ? EXTERNAL_CREATE_COMMENT_MUTATION : INTERNAL_CREATE_COMMENT_MUTATION;
  }

  function getNewlyAddedComment(newComments, parentId) {
    // When a reply comment is created, the root (parent) comment is returned instead. This is because
    // only root comments can be selected.
    if (parentId) {
      return newComments.find((c) => c.id === parentId);
    }

    return newComments[0];
  }

  async function addComment({
    content,
    visibility,
    parentId,
    editorKey,
    editorRanges,
    editorText,
  }: AddComment) {
    if (!content) {
      return Promise.resolve(null);
    }

    if (!previewCanComment) {
      await new Promise((resolve) => {
        previewIdentifyModal.show({
          props: {
            onIdentify: resolve,
          },
        });
      });
    }

    return addCommentMutation({
      variables: {
        input: {
          model_id: modelId,
          model_type: modelType,
          content,
          visibility,
          parent_id: parentId,
          editor_key: editorKey,
          editor_ranges: JSON.stringify(editorRanges),
          editor_text: editorText?.flatMap((item) => item.split('\n')),
        },
      },
    }).then((response) => {
      emitCreateEvent(getNewlyAddedComment(response.data.createComment.comments, parentId));
      return response;
    });
  }

  // Render
  return {
    // Properties from CommentsContext.tsx
    modelId,
    modelType,
    filter,
    setFilter,
    selectedCommentId,
    setSelectedCommentId,
    isNewGeneralCommentActive,
    setIsNewGeneralCommentActive,

    // Properties from this hook
    isCommentsSetupDone: data?.comments !== undefined,
    comments,
    refetchComments,
    loadingCommentUsers,
    addComment,
    loadingAddComment,
    modelSignKey,
  };
}

useComments.fragments = fragments;

export default useComments;
