import { Dispatch, ReactNode, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CommentsContext from '@/comments/context/CommentsContext';
import { COMMENTS_FILTER_OPTIONS } from '@/comments/constants/Constants';

type ModelTypeUnion =
  | 'Story'
  | 'Postset'
  | 'WebsiteContent'
  | 'Newsletter'
  | 'Webinar'
  | 'Podcast'
  | 'Ebook'
  | 'VideoProject'
  | 'Task';

type FilterUnion = -1 | 1 | 2 | 3;

type CommentsProviderType = {
  modelId: string;
  modelType: ModelTypeUnion;
  children: ReactNode;
};

export type CommentsProviderReturnType = {
  modelId: string;
  modelType: ModelTypeUnion;
  filter: FilterUnion;
  setFilter: Dispatch<SetStateAction<FilterUnion>>;
  selectedCommentId?: string;
  setSelectedCommentId: Dispatch<SetStateAction<string | null>>;
  isNewGeneralCommentActive: boolean;
  setIsNewGeneralCommentActive: Dispatch<SetStateAction<boolean>>;
};

function CommentsProvider({ modelId, modelType, children }: CommentsProviderType) {
  // States
  const [filter, setFilter] = useState<FilterUnion>(COMMENTS_FILTER_OPTIONS.open as FilterUnion);
  const [selectedCommentId, setSelectedCommentId] = useState<string | null>(null);
  const [isNewGeneralCommentActive, setIsNewGeneralCommentActive] = useState(false);

  const history = useHistory();
  const urlQueryParams = new URLSearchParams(window.location.search);
  const hashUrlQueryParams = new URLSearchParams(history.location.search);
  const isSyncUrlQueryParams = urlQueryParams.has('comment') && !hashUrlQueryParams.has('comment');
  const [isInitialized, setIsInitialized] = useState(!isSyncUrlQueryParams);
  const providerValue = useMemo(
    () => ({
      modelId,
      modelType,
      filter,
      setFilter,
      selectedCommentId,
      setSelectedCommentId,
      isNewGeneralCommentActive,
      setIsNewGeneralCommentActive,
    }),
    [
      modelId,
      modelType,
      filter,
      setFilter,
      selectedCommentId,
      setSelectedCommentId,
      isNewGeneralCommentActive,
      setIsNewGeneralCommentActive,
    ],
  );

  // If a page uses a hash router we need to modify the URL and move the query parameters to the hash router.
  function checkQueryParams() {
    if (isSyncUrlQueryParams) {
      const commentId = urlQueryParams.get('comment');

      removeCommentParam();
      addCommentParamToHashRouter(commentId);
    }
  }

  function removeCommentParam(): void {
    urlQueryParams.delete('comment');
    const urlQueryParamsString = urlQueryParams.toString() ? `?${urlQueryParams}` : '';
    window.history.replaceState(null, '', window.location.pathname + urlQueryParamsString);
  }

  function addCommentParamToHashRouter(commentId: string): void {
    hashUrlQueryParams.set('comment', commentId);
    history.replace({
      search: hashUrlQueryParams.toString(),
    });
  }

  useEffect(() => {
    if (!isInitialized) {
      checkQueryParams();
      setIsInitialized(true);
    }
  }, []);

  if (!isInitialized) {
    return null;
  }

  return <CommentsContext.Provider value={providerValue}>{children}</CommentsContext.Provider>;
}

export default CommentsProvider;
